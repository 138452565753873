import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Rating, Typography, Box, Slide, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Star } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  fontSize: '3rem',
  color: theme.palette.primary.main,
}));

const FeedbackOption = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(1.5),
  borderRadius: 8,
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
}));

const RatingModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validationSchema = Yup.object().shape({
    rating: Yup.number().min(1, t('components.ratingModal.ratingRequired')).required(t('components.ratingModal.ratingRequired')),
    selectedOption: Yup.string().required(t('components.ratingModal.optionRequired')),
    feedback: Yup.string().when('selectedOption', {
      is: 'other',
      then: Yup.string().required(t('components.ratingModal.feedbackRequired')).min(10, t('components.ratingModal.feedbackTooShort')),
    }),
  });

  const formik = useFormik({
    initialValues: {
      rating: 0,
      selectedOption: '',
      feedback: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      handleClose();
      // Ici, vous pouvez ajouter la logique pour envoyer les données au serveur
    },
  });

  return (
    <>
      <Button variant="contained" onClick={handleOpen} sx={{ borderRadius: 28, padding: '10px 24px' }}>
        {t('components.ratingModal.giveYourFeedback')}
      </Button>
      <StyledDialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Typography variant="h4" component="div" gutterBottom align="center">
              {t('components.ratingModal.evaluateOurApp')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Fade in={open} timeout={800}>
              <Box>
                <Typography variant="subtitle1" gutterBottom align="center">
                  {t('components.ratingModal.feedbackHelpsUs')}
                </Typography>
                <Box display="flex" justifyContent="center" my={3}>
                  <StyledRating
                    name="rating"
                    value={formik.values.rating}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('rating', newValue);
                    }}
                    icon={<Star fontSize="inherit" />}
                    emptyIcon={<Star fontSize="inherit" />}
                  />
                </Box>
                {formik.touched.rating && formik.errors.rating && (
                  <Typography color="error">{formik.errors.rating}</Typography>
                )}
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" sx={{ mb: 2 }}>{t('components.ratingModal.chooseAnOption')}</FormLabel>
                  <RadioGroup
                    aria-label="feedback"
                    name="selectedOption"
                    value={formik.values.selectedOption}
                    onChange={formik.handleChange}
                  >
                    <FeedbackOption 
                      value="excellent" 
                      control={<Radio />} 
                      label={<><IconWrapper></IconWrapper>{t('components.ratingModal.excellentService')}</>} 
                    />
                    <FeedbackOption 
                      value="fast" 
                      control={<Radio />} 
                      label={<><IconWrapper></IconWrapper>{t('components.ratingModal.fastTransfers')}</>} 
                    />
                    <FeedbackOption 
                      value="interface" 
                      control={<Radio />} 
                      label={<><IconWrapper></IconWrapper>{t('components.ratingModal.userFriendlyInterface')}</>} 
                    />
                    <FeedbackOption value="other" control={<Radio />} label={t('components.ratingModal.other')} />
                  </RadioGroup>
                </FormControl>
                {formik.touched.selectedOption && formik.errors.selectedOption && (
                  <Typography color="error">{formik.errors.selectedOption}</Typography>
                )}
                {formik.values.selectedOption === 'other' && (
                  <TextField
                    fullWidth
                    margin="normal"
                    name="feedback"
                    label={t('components.ratingModal.additionalComments')}
                    multiline
                    rows={4}
                    value={formik.values.feedback}
                    onChange={formik.handleChange}
                    error={formik.touched.feedback && Boolean(formik.errors.feedback)}
                    helperText={formik.touched.feedback && formik.errors.feedback}
                    variant="outlined"
                    sx={{ mt: 3 }}
                  />
                )}
              </Box>
            </Fade>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('components.ratingModal.cancel')}
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{ borderRadius: 28 }}>
              {t('components.ratingModal.submit')}
            </Button>
          </DialogActions>
        </form>
      </StyledDialog>
    </>
  );
};

export default RatingModal;