import React, { useEffect, useState } from 'react'
import './Profil.css';
import Footer from '../../components/footer/Footer';

import { useNavigate, Outlet } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import SidebarComponent from './components/sidebar';
import { Box } from '@mui/material';
import { Toolbar } from 'primereact/toolbar';
import ProfilComponent from './components/ProfilComponent';
import { Route, Routes, Link } from 'react-router-dom';
import AccountForm from './components/AccountForm';
import { useTranslation, Trans } from 'react-i18next';

const Profil = () => {
    const navigate = useNavigate();
    const [visibleLeft, setVisibleLeft] = useState(false);
    const { t } = useTranslation();
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
        } else {
            navigate('/')
        }
    }, [])

    return (
        <div className="profil-page page flex flex-column " style={{
            minHeight: '93vh'
        }}>
            <div className='grid mt-4 w-full flex-grow-1 max-width-container m-auto'>
                <SidebarComponent className={'sidebar p-4 md:p-6 col-12 md:col-4 hidden md:block'} />

                <div className='col-12 md:col-8  p-4 md:p-6 '>
                    <Outlet />
                </div>
            </div >
            <Footer />
        </div >
    );
}

export default Profil;
