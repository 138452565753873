import React, { useEffect, useRef, useState } from 'react'
import '../Profil.css';

import { Carousel } from 'primereact/carousel';
import { format } from 'date-fns';
import announcementApiService from '../../../services/moneyAnnouncementApiService';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Box } from '@mui/material';
import { Toolbar } from 'primereact/toolbar';
import currencyAnnouncementApiService from '../../../services/currencyAnnouncementApiService';
import { Knob } from 'primereact/knob';
import userApiService from '../../../services/userApiService';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const ProfilComponent = () => {
    const [user, setUser] = useState({})
    const navigate = useNavigate();
    const [visibleLeft, setVisibleLeft] = useState(false);
    const { t, i18n } = useTranslation();
    const [key, setKey] = useState(0); // State to force re-render

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const [rating, setRating] = useState(2);
    const [exchangeMoneyAnnouncements, setExchangeMoneyAnnouncements] = useState([]);
    const [exchangeCurrencyAnnouncements, setExchangeCurrencyAnnouncements] = useState([]);
    const [transations, setTransations] = useState([]);
    const [userFillingInformation, setUserFillingInformation] = useState({
        percent: 0,
        fillToComplete: ""
    });

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user && user?.id) {
            setUser(user);
            getUserMoneyAnnouncement(user?.id);
            getUserCurrenciesAnnouncement(user?.id);
            getPercentOfFillAccount(user?.id);
        }
        
        ReactGA.send({
            hitType: "pageview",
            page: "profil_page"
        });
    }, [])

    // Listen for language changes and force re-render
    useEffect(() => {
        const handleLanguageChange = () => {
            setKey(prevKey => prevKey + 1); // Increment key to force re-render
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    const getUserMoneyAnnouncement = async (userId) => {
        let announcements = await announcementApiService.getAnnounceByUserId(userId);
        if (announcements?.error) {
            setExchangeMoneyAnnouncements([]);
        } else {
            setExchangeMoneyAnnouncements(announcements);
        }
    }

    const getUserCurrenciesAnnouncement = async (userId) => {
        let announcements = await currencyAnnouncementApiService.getAnnounceByUserId(userId);
        if (announcements?.error) {
            setExchangeCurrencyAnnouncements([]);
        } else {
            setExchangeCurrencyAnnouncements(announcements);
        }
    }

    const getStatusOfAnnouncement = (announce) => {
        if (announce?.expiredAt) {
            let expiredDay = parseInt(format(announce?.expiredAt, 'dd'), 10);
            let date = parseInt(format(new Date(), 'dd'), 10);

            let expiredMonth = parseInt(format(announce?.expiredAt, 'MM'), 10);
            let month = parseInt(format(new Date(), 'MM'), 10);

            let expiredYear = parseInt(format(announce?.expiredAt, 'yyyy'), 10);
            let year = parseInt(format(new Date(), 'yyyy'), 10);

            if ((expiredDay - date < 0) && expiredMonth == month && expiredYear == year) {
                return "danger";
            } else {
                if ((expiredDay - date >= 0 || (expiredDay - date <= 5)) && expiredMonth == month && expiredYear == year) {
                    return "warning";
                } else {
                    return "info";
                }
            }
        }

        return "info";
    }

    const getStatusLabelOfAnnouncement = (announce) => {
        if (announce?.expiredAt) {
            let expiredDay = parseInt(format(announce?.expiredAt, 'dd'), 10);
            let date = parseInt(format(new Date(), 'dd'), 10);

            let expiredMonth = parseInt(format(announce?.expiredAt, 'MM'), 10);
            let month = parseInt(format(new Date(), 'MM'), 10);

            let expiredYear = parseInt(format(announce?.expiredAt, 'yyyy'), 10);
            let year = parseInt(format(new Date(), 'yyyy'), 10);

            if ((expiredDay - date < 0) && expiredMonth == month && expiredYear == year) {
                return t('profilPage.hasExpired');
            } else {
                if ((expiredDay - date >= 0 || (expiredDay - date <= 5)) && expiredMonth == month && expiredYear == year) {
                    return t('profilPage.expiredIn', {
                        expiredIn: (expiredDay - date),
                    });
                } else {
                    return t('profilPage.expiredAt', {
                        expiredAt: format(announce?.expiredAt, 'dd MMM yyyy'),
                    });
                }
            }
        }

        return t("global.pending");
    }

    const getPercentOfFillAccount = async (userId) => {
        let percentOfFillingAccount = await userApiService.getPercentOfFillingAccountOfUser(userId);
        if (percentOfFillingAccount?.error) {

        } else {
            setUserFillingInformation(percentOfFillingAccount);
        }
    }

    const exchangeMoneyAnnouncementTemplate = (exchangeMoneyAnnouncement) => {
        return (
            <div className="py-3 md:py-5 px-1 md:px-2">
                <div className="offer-card bg-blue-50  p-2 md:p-3 cursor-pointer hover:bg-blue-100" onClick={() => navigate('/profil/announce/' + exchangeMoneyAnnouncement?.id)}>
                    <div className="">
                        <h3 className='mt-0'>{t('profilPage.city', {
                            cityWhereHeSentName: exchangeMoneyAnnouncement?.cityWhereHeSent?.name,
                            cityWhereHeRetrieveName: exchangeMoneyAnnouncement?.cityWhereHeRetrieve?.name
                        })}</h3>
                        <p className='mt-0 mb-2'>{t('profilPage.amountToSend')} <strong>{exchangeMoneyAnnouncement?.amountToSend + " " + exchangeMoneyAnnouncement?.currencyFrom}</strong></p>
                        <p className='mt-0 mb-2'>{t('profilPage.amountToReceive')} <strong>{exchangeMoneyAnnouncement?.amountToRetrieve + " " + exchangeMoneyAnnouncement?.currencyTo}</strong></p>
                        <p className='mt-0 mb-2'>{t('profilPage.publishedAt')} <strong>{format(exchangeMoneyAnnouncement.announceAt, 'dd MMM yyyy')}</strong></p>
                        <p className='mt-0 mb-2 text-right'>
                            <Tag severity={getStatusOfAnnouncement(exchangeMoneyAnnouncement)} value={getStatusLabelOfAnnouncement(exchangeMoneyAnnouncement)}></Tag>
                        </p>
                    </div>
                </div>
            </div >
        );
    };

    const exchangeCurrencyAnnouncementTemplate = (exchangeCurrencyAnnouncement) => {
        return (
            <div className="py-3 md:py-5 px-1 md:px-2">
                <div className="offer-card bg-blue-50  p-2 md:p-3 cursor-pointer hover:bg-blue-100" onClick={() => navigate('/profil/announce/' + exchangeCurrencyAnnouncement?.id)}>
                    <div className="">
                        <h3 className='mt-0'>{t('profilPage.exchangeCity')}  {exchangeCurrencyAnnouncement?.exchangeCity?.name}</h3>
                        <p className='mt-0 mb-2'>{t('profilPage.amountToSend')} <strong>{exchangeCurrencyAnnouncement?.amountToSend + " " + exchangeCurrencyAnnouncement?.currencyFrom}</strong></p>
                        <p className='mt-0 mb-2'>{t('profilPage.amountToReceive')} <strong>{exchangeCurrencyAnnouncement?.amountToRetrieve + " " + exchangeCurrencyAnnouncement?.currencyTo}</strong></p>
                        <p className='mt-0 mb-2'>{t('profilPage.publishedAt')} <strong>{format(exchangeCurrencyAnnouncement.announceAt, 'dd MMM yyyy')}</strong></p>

                        <p className='mt-0 mb-2 text-right'>
                            <Tag severity={getStatusOfAnnouncement(exchangeCurrencyAnnouncement)} value={getStatusLabelOfAnnouncement(exchangeCurrencyAnnouncement)}></Tag>
                        </p>
                    </div>
                </div>
            </div >
        );
    };



    const percentProfilContent = (
        <React.Fragment>
            <Box className='flex justify-content-center align-items-center gap-2 cursor-pointer'>
                <Knob valueColor="#3498db" value={userFillingInformation.percent} d valueTemplate={'{value}%'} strokeWidth={5} min={0} max={100} />
                <Box>
                    <h3 className='mt-0 mb-2 text-lg'>{t('profilPage.profilNotCompleted')}</h3>
                    <p className='line-height-3 my-0'>{t('profilPage.completeInformation')} {userFillingInformation.fillToComplete}</p>
                </Box>
            </Box>
        </React.Fragment>
    );


    return (
        <>
            <h1 className='mb-5 text-color font-normal'>{t('global.goodMorning')} {user.firstname}</h1>
            <ul className='list-none p-0 w-full md:w-4'>
                <li className='text-lg text'>
                    {t('profilPage.member')}  {new Date(user.createAt).getFullYear()}
                </li>
            </ul>
            <ul className='list-none p-0 mt-4'>
                <li className='text-lg pb-3 text-color'>
                    <i className="pi text-500 pi-phone text-primary"></i> { "+" + user.phone}
                </li>
                <li className='text-lg text-color'>
                    <i className="pi text-500 pi-envelope text-primary"></i> {user.email}
                </li>
            </ul>
            <div className='mb-3 mt-4' style={{
                minHeight: '3rem',
            }}>
                {
                    userFillingInformation.percent < 100 ? <Toolbar end={percentProfilContent} /> : ''
                }

            </div>

            <div className='announce-container mb-4'>
                <h1 className=' text-color font-normal'>{t("profilPage.announceLinkToMoney")}</h1>
                {
                    exchangeMoneyAnnouncements.length > 0 ? (
                        <Carousel value={exchangeMoneyAnnouncements} numVisible={3} numScroll={3} showIndicators={false} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                            autoplayInterval={10000} itemTemplate={exchangeMoneyAnnouncementTemplate} />
                    ) : (
                        <p>{t("global.announceNotPresent")}</p>
                    )
                }
            </div>

            <div className='announce-container mb-4'>
                <h1 className=' text-color font-normal'>{t("profilPage.announceLinkToCurency")}</h1>
                {
                    exchangeCurrencyAnnouncements.length > 0 ? (
                        <Carousel value={exchangeCurrencyAnnouncements} numVisible={3} numScroll={3} showIndicators={false} responsiveOptions={responsiveOptions} className="custom-carousel" circular
                            autoplayInterval={10000} itemTemplate={exchangeCurrencyAnnouncementTemplate} />
                    ) : (
                        <p>{t("global.announceNotPresent")}</p>
                    )
                }
            </div>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-VJ0Y9D0VXY"></script>
            <script src='https://soluchange.com/assets/scripts/google_analytic.js'> </script>
        </>
    );
}

export default ProfilComponent;
